import React, { Fragment } from "react";
// import Header from "../components/Header/Header";
// import NewHeader from "../components/Header/NewHeader";
import NewHeader1 from "../components/Header/NewHeader1";

const AppLayout = (props) => {
  return (
    <Fragment>
      {/* <NewHeader /> */}
      {/* <Header /> */}

      <NewHeader1 />
      <div className="">{props.children}</div>
    </Fragment>
  );
};

export default AppLayout;
