import config from "../config";
import { apiCalls, authApiCalls } from "./apiCalls";
import { getValue } from "../utils/sessionManager";
import store from "../store/index";

export const sendEmailNotification = async (values) => {
  const url = config.base_url_4 + `/api/v1/common/send_email_notification`;
  const user = getValue("user");
  //const domain = getValue("subdomain")
  let mail_redirect_url = window.location.origin;
  const reqObject = {
    action_type: values.action_type,
    name: values.name,
    email: values.email,
    role: values.role.length === 0 ? user.role : values.role,
    user_id: values.userId,
    sub_advisor_name: user.advisor_name,
    goal_name: values.goal_name,
    //domain_name:domain,
    domain_name: mail_redirect_url,
  };

  try {
    await apiCalls("post", url, reqObject);
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("sendEmailNotification, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const sendEmailNotificationUserSelfSignup = async (values) => {
  const url = config.base_url_4 + `/api/v1/common/send_email_notification_client_self_signup`;
  const authToken = store.getState().alchemyServiceAuthToken.alchemyServiceAuthToken;

  const user = getValue("user");
  const subdomain = getValue("subdomain");
  let mail_redirect_url = window.location.origin;
  // console.log(domain,'$$$$');
  const reqObject = {
    action_type: values.action_type,
    name: values.name,
    email: values.email,
    role: values.role,
    user_id: values.user_id,
    advisor_name: user.advisor_name,
    //domain_name:domain,
    domain_name: mail_redirect_url,
    sub_domain: subdomain,
    authToken: authToken || "",
  };

  try {
    await apiCalls("post", url, reqObject);
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("sendEmailNotificationUserSelfSignup, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const camsUpload = async (values) => {
  const url = config.base_url_4 + `/api/v1/common/automate_cams`;

  const user = getValue("user");
  // console.log(user);
  const reqObject = {
    name: user.client_name,
    email: values.email,
    pan_number: user.pan_number,
    password: "pulse@123",
    confirm_password: "pulse@123",
  };
  try {
    await apiCalls("post", url, reqObject);
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("camsUpload, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

// export const sendAdvisorSignUpEmailNotification = async (values) => {
//   const url = config.base_url_4 + `/api/v1/common/send_email_notification`;
//   const user = getValue("user");
//   const reqObject = {
//     action_type: values.action_type,
//     name: values.name,
//     email: values.email,
//     role: values.role.length === 0 ? user.role : values.role,
//     sub_advisor_id: values.sub_advisor_id,
//   };

//   try {
//     await apiCalls("post", url, reqObject);
//   } catch (error) {
//     if (error.response) {
//       throw new Error(error.response.data.err);
//     } else {
//       console.error("sendEmailNotification, server err:", error.message);
//       throw new Error(error.message);
//     }
//   }
// };

export const sendEmailNotificationSubadvisorSelfSignUp = async (values) => {
  const url = config.base_url_4 + `/api/v1/common/send_email_notification_sub_advisor_self_signup`;
  // const domain = getValue("subdomain")
  // const user = getValue("user");
  //console.log(domain,"test1 backend");
  let mail_redirect_url = window.location.origin;
  //console.log('im in',window.location.origin)
  const reqObject = {
    action_type: values.action_type,
    name: values.name,
    email: values.email,
    role: values.role,
    user_id: values.user_id,
    password: values.password,
    domain_name: mail_redirect_url,
  };

  try {
    await apiCalls("post", url, reqObject);
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("sendEmailNotificationSubadvisorSelfSignUp, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

//post
export const createNotification = (values) => {
  const url = config.base_url_4 + `/api/v1/notification/notification`;
  const reqObject = {
    client_id: values.client_id,
    advisor_id: values.advisor_id,
    notification_details: values.notification_details,
    action_by: values.action_by,
    created_from: values.created_from,
    is_viwed: false,
    notification_extra_details: values.notification_extra_details,
  };
  return apiCalls("post", url, reqObject);
};
//get
export const getNotification = () => {
  const user = getValue("user");
  const url = config.base_url_4 + `/api/v1/notification/notification?client_id=${user.client_id}`;
  const reqObject = {
    // client_id: user.client_id,
  };
  return apiCalls("get", url, reqObject);
};
//update
export const updateNotification = (id, values) => {
  const url = config.base_url_4 + `/api/v1/notification/notification/${id}`;
  const reqObject = {
    client_id: values.client_id,
    advisor_id: values.advisor_id,
    notification_details: values.notification_details,
    notification_extra_details: values.notification_extra_details,
    action_by: values.action_by,
    created_from: values.created_from,
    is_viwed: values.is_viwed,
  };
  return apiCalls("put", url, reqObject);
};
//delete
export const deleteNotification = (id, values) => {
  const url = config.base_url_4 + `/api/v1/notification/notification/${id}`;
  const reqObject = {
    is_viwed: values.is_viwed,
  };
  return authApiCalls("delete", url, reqObject);
};
//post
export const createMfcas = (value) => {
  const url = config.base_url_4 + `/api/v1/mfcas/mf_cas`;
  const reqObject = {
    client_id: value.client_id,
    advisor_id: value.advisor_id,
    sub_advisor_id: value.sub_advisor_id,
    is_email_notification: value.is_email_notification,
    is_email_notification_cas: value.is_email_notification_cas,
    cams_apply_status: value.cams_apply_status,
    cams_apply_date: value.cams_apply_date,
    cas_upload_date: value.cas_upload_date,
    cas_upload_status: value.cas_upload_status,
  };
  return apiCalls("post", url, reqObject);
};
//get
export const getMfcas = () => {
  const user = getValue("user");
  const url = config.base_url_4 + `/api/v1/mfcas/mf_cas?client_id=${user.client_id}`;
  const reqObject = {};
  return apiCalls("get", url, reqObject);
};

//update
export const updateMfcas = async (id, value) => {
  const url = config.base_url_4 + `/api/v1/mfcas/mf_cas/${id}`;
  const reqObject = {
    client_id: value.client_id,
    advisor_id: value.advisor_id,
    sub_advisor_id: value.sub_advisor_id,
    is_email_notification: value.is_email_notification,
    is_email_notification_cas: value.is_email_notification_cas,
    cams_apply_status: value.cams_apply_status,
    cams_apply_date: value.cams_apply_date,
    cas_upload_date: value.cas_upload_date,
    cas_upload_status: value.cas_upload_status,
  };
  try {
    await apiCalls("put", url, reqObject);
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("updateMfcas, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const createSuggestedModelPortfolio = (values) => {
  const url = config.base_url_4 + `/api/v1/suggestedModelPortfolio/suggested_model_portfolio_funds`;
  const reqObject = {
    client_id: values.client_id,
    advisor_id: values.advisor_id,
    created_by: values.created_by,
    scheme_code: values.scheme_code,
    model_portfolio_invest: values.model_portfolio_invest,
    perc_allocation: values.perc_allocation,
    scheme_name: values.scheme_name,
    sip_value: values.sip_value,
    one_time: values.one_time,
  };
  return apiCalls("post", url, reqObject);
};

export const getSuggestedModelPortfolio = () => {
  const user = getValue("user");
  let model_portfolio_invest_type = "no";
  const url =
    config.base_url_4 +
    `/api/v1/suggestedModelPortfolio/suggested_model_portfolio_funds?client_id=${user.client_id}&model_portfolio_invest=${model_portfolio_invest_type}`;
  return apiCalls("get", url, {});
};

//delete
export const deleteSuggestedModelPortfolio = () => {
  const user = getValue("user");
  const url = config.base_url_4 + `/api/v1/suggestedModelPortfolio/suggested_model_portfolio_funds`;
  const reqObject = {
    client_id: user.client_id,
    model_portfolio_invest: "no",
    created_by: "SUBADVISOR",
  };
  return authApiCalls("delete", url, reqObject);
};
//update
export const updateSuggestedModelPortfolio = async (id, values) => {
  const user = getValue("user");
  const url =
    config.base_url_4 + `/api/v1/suggestedModelPortfolio/suggested_model_portfolio_funds/${id}`;
  const reqObject = {
    client_id: user.client_id,
    advisor_id: user.advisor_id,
    created_by: values.created_by,
    scheme_code: values.scheme_code,
    model_portfolio_invest: values.model_portfolio_invest,
    perc_allocation: values.perc_allocation,
    scheme_name: values.scheme_name,
    sip_value: values.sip_value,
    one_time: values.one_time,
  };
  try {
    await apiCalls("put", url, reqObject);
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("updateSuggestedModelPortfolio, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const createInvestmentThemes = (values) => {
  const url = config.base_url_4 + `/api/v1/investmentThemes/investment_themes`;
  const reqObject = {
    client_id: values.client_id,
    advisor_id: values.advisor_id,
    created_by: values.created_by,
    scheme_code: values.scheme_code,
    scheme_name: values.scheme_name,
    one_time: values.one_time,
    sip_value: values.sip_value,
    investment_theme_type: values.investment_theme_type,
    is_invested: false,
  };
  return apiCalls("post", url, reqObject);
};

export const getInvestmentThemes = () => {
  const user = getValue("user");
  const url =
    config.base_url_4 + `/api/v1/investmentThemes/investment_themes?client_id=${user.client_id}`;
  const reqObject = {};

  return apiCalls("get", url, reqObject);
};

//update
export const updateInvestmentThemes = async (id, values) => {
  // const user = getValue("user");
  const url = config.base_url_4 + `/api/v1/investmentThemes/investment_themes/${id}`;
  const reqObject = {
    client_id: values.client_id,
    advisor_id: values.advisor_id,
    created_by: values.created_by,
    scheme_code: values.scheme_code,
    scheme_name: values.scheme_name,
    one_time: values.one_time,
    sip_value: values.sip_value,
    investment_theme_type: values.investment_theme_type,
    is_invested: values.is_invested,
  };
  try {
    await apiCalls("put", url, reqObject);
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("updateInvestmentThemes, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

//delete
export const deleteInvestmentThemes = (values) => {
  const user = getValue("user");
  const url = config.base_url_4 + `/api/v1/investmentThemes/investment_themes`;
  const reqObject = {
    client_id: user.client_id,
    is_invested: false,
    investment_theme_type: values.investment_theme_type,
  };
  return authApiCalls("delete", url, reqObject);
};
