import moment from "moment";
import { getValue } from "./sessionManager";

export const roundNumber = (value) => {
  const number = Number(value);
  if (Number.isInteger(number)) {
    return number;
  } else {
    return number.toFixed(0);
  }
};

export const dateFormat = (value, format) => {
  return moment(value).format(format);
};

export const isNavActive = (moduleName) => {
  const moduleRule = getValue(moduleName);
  const subModuleList = moduleRule.submodule_list;
  const activeSubmoduleList = subModuleList.filter((item, index) => item.isActive);
  if (activeSubmoduleList.length > 0) return true;
  return false;
};

export const getSubDomain = () => {
  const parts = window.location.hostname.split('.');
  if (parts.includes('localhost')) {
    return parts.length > 1 && parts[0].toLowerCase() !== 'www' ? parts[0] : null;
  }
  else if (parts.length <= 2 || parts[0].toLowerCase() === 'www') {
    return null;
  } else {
    return parts[0];
  }
}

export const hasSubdomain = () => {
  const subdomain = getSubDomain();
  return subdomain;
};

// export const dateSub = (value1, value2) => {

// }
