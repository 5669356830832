import config from "../config";
import { apiCalls, authApiHeader } from "./apiCalls";
import { getValue } from "../utils/sessionManager";
import store from "../store/index";
import { hasSubdomain } from "../utils/utils";

export const getAuthToken = async () => {
  const url = config.base_url + `/partner_auth/login`;
  // const url = `https://alchemy-service-prod.pulselabs.co.in/partner_auth/login`;
  const reqObject = {
    partner: config.partner,
    partner_key: config.partner_key,
  };

  try {
    const result = await apiCalls("post", url, reqObject);
    return result;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("authToken, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const loginValidation = (values) => {
  const url = config.base_url + `/user/auth`;
  const authToken = store.getState().alchemyServiceAuthToken.alchemyServiceAuthToken;
  const subdomain = hasSubdomain();
  const reqObject = {
    user_id: values.user_id,
    password: values.password,
    sub_domain: subdomain || ''
  };
  const headerObj = {
    Authorization: `Bearer ${authToken}`,
  };
  return authApiHeader("post", url, headerObj, reqObject);
};

// export const loginValidation = (values) => {
//   const url = config.base_url + `/user/auth`;

//   const reqObject = {
//     user_id: values.user_id,
//     password: values.password,
//   };
//   return apiCalls("post", url, reqObject);
// };

// ------Pre Login---------
export const userSignUp = (values, authRes) => {
  //  console.log(values)
  //  const user = getValue("user");
  const url = config.base_url + `/user/create`;
  const headerObj =
    config.node_env === "production" ? { Authorization: `Bearer ${authRes.data.token}` } : {};
  const subdomain = hasSubdomain()
  const reqObject = {
    name: values.name,
    role: "advisor",
    email: values.email,
    mobile: values.mobile,
    client_type: "",
    password: values.password,
    //"code": values.code,
    pan_number: "",
    advisor_type: "MFD",
    company: values.company,
    advisor_id: values.code,
    sub_domain:subdomain || ''
  };
  return authApiHeader("post", url, headerObj, reqObject);
};

export const clientSignup = async (values) => {
  const user = getValue("user");
  const url = config.base_url + `/investor/create`;
  const advisorId = getValue("advisor_id");
  const authToken = store.getState().alchemyServiceAuthToken.alchemyServiceAuthToken;
  const headerObj = {
    Authorization: `Bearer ${authToken}`,
  };

  const subdomain = hasSubdomain()

  // const headerObj =
  //   config.node_env === "production" ? { Authorization: `Bearer ${authRes.data.token}` } : {};

  const reqObject = {
    client_name: values.name,
    client_email: values.email_address,
    client_mobile: values.mobile,
    role: "client",
    advisor_id: advisorId,
    advisor_type: "",
    pan_number: values.pan_number,
    password: values.password,
    company: "",
    client_type: "",
    bse_user_id: "",
    bse_member_id: "",
    bse_password: "",
    sub_advisor_id: values.sub_advisor_code,
    euin_number: user && user.euin_number ? user.euin_number :"",
    sub_domain:subdomain || ''
  };
  try {
    const result = await authApiHeader("post", url, headerObj, reqObject);
    return result;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("clientSignup, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const paswordUpdate = (values) => {
  const user = getValue("user");
  const url = config.base_url + "/user/changepwd";
  const authToken = store.getState().alchemyServiceAuthToken.alchemyServiceAuthToken;
  const headerObj = {
    Authorization: `Bearer ${authToken}`,
  };

  // const headerObj =
  //   config.node_env === "production" ? { Authorization: `Bearer ${authRes.data.token}` } : {};

  const reqObject = {
    user_id: user.user_id.toString(),
    password: "password",
    new_password: values.new_password,
  };

  return authApiHeader("post", url, headerObj, reqObject);
};

export const getforgotPassword = async (values, authRes) => {
  const url = config.base_url + "/user/verify";
  const reqObject = {
    role: "CLIENT",
    email: values.email,
  };
  const headerdata =
    config.node_env === "production" ? { Authorization: `Bearer ${authRes.data.token}` } : "";
  try {
    const result = await authApiHeader("post", url, headerdata, reqObject);
    return result;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("getforgotPassword, server err:", error.message);
      throw new Error(error.message);
    }
  }
};

export const forgetpasswordUpdate = async (values, authRes) => {
  //console.log(authRes.data.token);
  const url = config.base_url + "/user/changepwd";
  const headerObj = config.node_env === "production" ? { Authorization: `Bearer ${authRes.data.token}` } : "";
  //console.log("headerObj",headerObj);
  const reqObject = {
    user_id: values.user_id.toString(),
    password: values.password,
    new_password: values.new_password,
  };
  try {
    const result = await authApiHeader("post", url, headerObj, reqObject);
    return result;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.err);
    } else {
      console.error("getforgotPassword, server err:", error.message);
      throw new Error(error.message);
    }
  }
};
